import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const fontMain = '"Lato", sans-serif'
const fontSecondary = '"helvetica", sans-serif'
const imp = '!important'
const pageBg = '#f9fafb'
const black = '#000'
const white = '#fff'
const grey = '#b3b3b3'
const greyLight = '#f3f4f6'

const hpTitle = {
  ...heading('30px', '30px', 500, 500, fontMain, fontMain, true),
  textAlign: `center ${imp}`,
  textTransform: `uppercase ${imp}`,
}

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid ${greyLight} ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `${greyLight} ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  themeFont: {
    main: 'Lato',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 750,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: fontMain,
    h1: {
      fontSize: 32,
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h2: {
      fontSize: 30,
      lineHeight: 1.2,
      fontWeight: 500,
    },
    h3: {
      fontSize: 18,
      lineHeight: 1.4,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.55,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.75,
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.75,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.17,
      fontWeight: 400,
    },
    button: {
      fontSize: 13,
      lineHeight: 1,
    },
  },
  palette: {
    common: {
      black: black,
      white: '#fff',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    primary: {
      main: black,
    },
    secondary: {
      main: black,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: '#f0f0f0',
    primaryTransparent: {
      main: 'rgb(0, 0, 0, .4)',
      light: greyLight,
    },
    secondaryTransparent: {
      main: 'rgba(0, 0, 0, .4)',
      light: greyLight,
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#FFEA28',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: '#525252',
    greyText: '#242424',
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '12px 24px',
        fontSize: 13,
        lineHeight: 1,
        borderRadius: 0,
      },
      sizeSmall: {
        padding: '6px 12px',
        fontSize: 12,
        lineHeight: 1,
      },
      sizeLarge: {
        padding: '18px 36px',
        fontSize: 13,
        lineHeight: 1,
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 10,
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          background: pageBg,
        },
        '.main': {
          background: pageBg,
        },
        '.header': {
          '&--top': {
            top: '40px !important',
          },
          '& .MuiContainer-root': {
            '@media (min-width: 1440px)': {
              maxWidth: '1870px',
            },
          },
          '&__cta': {
            '&.MuiButtonBase-root': {
              fontSize: 11,
            },
            '&--account': {
              '& .MuiSvgIcon-root': {
                background: `url("/images/spyder/account.svg") no-repeat 50% 50% ${imp}`,
                backgroundSize: `contain ${imp}`,
                '&>path': {
                  display: `none ${imp}`,
                  visibility: `hidden ${imp}`,
                },
              },
              '.header--transparent &': {
                //if change color on header transparent
              },
            },
          },
          '&__lang': {
            fontSize: 11,
          },
          '& .menu': {
            '&--primary': {
              '& .menu__list--primary': {
                '& .menu__item': {
                  '& >a': {
                    fontSize: `14px ${imp}`,
                    fontFamily: `${fontSecondary} ${imp}`,
                  },
                  '&:after': {
                    content: 'none',
                  },
                },
              },
            },
            '&__list': {
              gap: `16px ${imp}`,
            },
          },
        },
        ' .page': {
          '&--home': {
            '& .section-hiw': {
              paddingTop: `20px ${imp}`,
            },
          },
        },
        '.banner': {
          height: `40px ${imp}`,
          '& .MuiTypography-root': {
            fontSize: `12px ${imp}`,
            fontFamily: `${fontSecondary} ${imp}`,
          },
        },
        '.hero__home': {
          '& .title': {
            fontSize: `40px ${imp}`,
          },
        },
        '.select': {
          ...customSelect,
        },
        '.cart-badge': {
          bottom: `auto ${imp}`,
          top: `12px ${imp}`,
          right: `12px ${imp}`,
          border: `0 ${imp}`,
          minWidth: `16px ${imp}`,
          width: `16px ${imp}`,
          height: `16px ${imp}`,
          lineHeight: `16px ${imp}`,
          fontSize: `9px ${imp}`,
          fontWeight: `400 ${imp}`,
          borderRadius: `50% ${imp}`,
        },
        '.shop': {
          '&__content': {
            background: `${pageBg} ${imp}`,
          },
        },
        '.shop-menu': {
          '&__list': {
            '& .MuiTab-root': {
              background: `${greyLight} ${imp}`,
              borderRadius: `3.75px ${imp}`,
              '&:hover, &:focus, &.Mui-selected': {
                color: `${white} ${imp}`,
                background: `${black} ${imp}`,
              },
            },
          },
        },
        '.productCard': {
          '&__cta': {
            color: `${white} !important`,
          },
        },
        '.hiw': {
          '&__img': {
            height: `100px ${imp}`,
            width: `auto ${imp}`,
          },
        },
        '.discover': {
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
            '&:before': {
              background: `none ${imp}`,
            },
            '@media (max-width: 1024px)': {
              margin: `0 10px ${imp}`,
            },
          },
          '&-product': {
            '&__title': {
              fontSize: `16px ${imp}`,
              fontWeight: `400 ${imp}`,
              textTransform: `lowercase ${imp}`,
              '&:first-letter': {
                textTransform: `capitalize ${imp}`,
              },
            },
            '&__price': {
              fontSize: `14px ${imp}`,
            },
            '&__content': {
              position: `static ${imp}`,
              padding: `16px 0 26px ${imp}`,
              background: `none ${imp}`,
              color: `${black} ${imp}`,
              textAlign: `left ${imp}`,
            },
          },
        },
        '.faq': {
          '&__item': {
            marginBottom: `0${imp}`,
            '&:last-child': {
              '& .faq__question, & .faq__answer': {
                borderBottom: `0 ${imp}`,
              },
            },
          },
          '&__question': {
            borderBottom: `1px solid ${grey} ${imp}`,
            margin: `0 ${imp}`,
            padding: `18px 0 ${imp}`,
            textTransform: `uppercase ${imp}`,
            '&:hover, &:focus': {
              background: `none ${imp}`,
            },
            '&[aria-expanded=true]': {
              color: `${black} ${imp}`,
              background: `none${imp}`,
              borderColor: `transparent ${imp}`,
              '&>.MuiButton-label:after': {
                transform: 'rotate(-180deg)',
              },
            },
            '&>.MuiButton-label': {
              minHeight: `26px ${imp}`,
              display: 'flex',
              alignItems: 'center',
              '& p': {
                display: 'block',
              },
              '& strong': {},
              '&:after': {
                content: '""',
                background:
                  'no-repeat 50% 50% url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjUgNy41VjE0SDcuNVY3LjVIMTRWNi41SDcuNVYwSDYuNVY2LjVIMFY3LjVINi41WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==")',
                marginRight: 24,
                marginLeft: 24,
                width: 14,
                height: 14,
                backgroundSize: `contain ${imp}`,
              },
              '& svg': {
                display: 'none',
              },
            },
          },
          '&__btn': {
            border: `0 ${imp}`,
            color: `${black} ${imp}`,
          },
          '&__answer': {
            padding: `0 0 20px ${imp}`,
            margin: `0 ${imp}`,
            borderBottom: `1px solid ${grey} ${imp}`,
            borderColor: `transparent ${imp}`,
            color: `${black} ${imp}`,
          },
        },
        '.product': {
          '&__desc': {
            '& a': {
              color: `#0000EE ${imp}`,
              'text-decoration': 'underline',
            },
          },
          '&__right': {
            '& .product__bottom': {
              justifyContent: `space-around ${imp}`,
              '& >a': {
                fontSize: `13px ${imp}`,
              },
            },
          },
        },
        '.section': {
          '&-faq, &-hiw, &-discover': {
            '&__title, &__subtitle': {
              ...hpTitle,
            },
          },
          '&-faq': {
            '& .faq__item': {
              '& .faq__question': {
                fontSize: `16px ${imp}`,
              },
            },
          },
        },
        '.footer': {
          '&__logo': {
            maxHeight: `70px ${imp}`,
          },
        },
      },
    },
  },
})

export default theme
